import { useCallback, useState } from 'react'

import type {
  CloseEvent,
  GetSignatureHandler,
  ParamPrepHandler,
  PreBatchCallback,
  PreBatchHandler,
  PrepareUploadParamsCallback,
  UploadHookOptions,
  UploadSignatureCallback,
  UploadWidgetCallbackHandlers,
  UploadWidgetEventHandler,
  UploadWidgetEventHandlers,
} from '@mntn-dev/files-shared'

import { assert } from '@mntn-dev/utilities'
import type { AnyFunction } from '@mntn-dev/utility-types'
import { CloudinaryWidgetScriptUrl } from '../configuration.ts'
import { logger } from '../logger.ts'
import type { Cloudinary } from '../types/cloudinary.ts'
import type { WidgetInstance } from '../types/widget-instance.ts'
import { createEventHandler } from './create-event-handler.ts'
import { useImperativeFunctions } from './use-imperative-functions.ts'
import { useScript } from './use-script.ts'

declare global {
  interface Window {
    cloudinary: Cloudinary
    cloudinaryWidget?: WidgetInstance
  }
}

type UseUploadWidgetProps = {
  callbackHandlers: UploadWidgetCallbackHandlers
  eventHandlers: UploadWidgetEventHandlers
  options: UploadHookOptions
}

export const UseUploadWidget = ({
  callbackHandlers: { onParamPrep, onPreBatch, onGetSignature },
  eventHandlers,
  options: initialOptions,
}: UseUploadWidgetProps) => {
  const { loaded: scriptLoaded } = useScript(CloudinaryWidgetScriptUrl)

  const [widget, setWidget] = useState<WidgetInstance | undefined>(undefined)

  // get memoized version of the widget imperative functions
  const { close } = useImperativeFunctions(widget)

  // get widget status booleans
  const isShowing = !!widget?.isShowing()
  const isMinimized = !!widget?.isMinimized()
  const isDestroyed = widget?.isDestroyed() ?? true // undefined becomes true because undefined is the same as being destroyed

  // create a custom open function that creates the widget if it doesn't exist
  const open = useCallback(() => {
    let createdWidget = false

    logger.debug('UseUploadWidget - open', {
      widget,
      'window.cloudinaryWidget': window.cloudinaryWidget,
    })

    // if there is no cloudinaryWidget or it is destroyed, create a new one
    if (!window.cloudinaryWidget || window.cloudinaryWidget.isDestroyed()) {
      createWidget(
        scriptLoaded,
        () => setWidget(undefined),
        onPreBatch,
        onParamPrep,
        onGetSignature,
        initialOptions,
        eventHandlers
      )
      createdWidget = true
    }

    // createWidget should have left a reference to the widget in the window object
    assert(window.cloudinaryWidget, 'window.cloudinaryWidget is undefined')

    // if the widget is not showing, open it
    if (!window.cloudinaryWidget.isShowing()) {
      window.cloudinaryWidget?.open()
    }

    // if the hook isn't already tracking the widget, update the hook
    if (widget !== window.cloudinaryWidget) {
      if (!createdWidget) {
        logger.warn('UseUploadWidget - hook lost track of widget', {
          'window.cloudinaryWidget': window.cloudinaryWidget,
        })
      }
      setWidget(window.cloudinaryWidget)
    }
  }, [
    widget,
    scriptLoaded,
    onPreBatch,
    onParamPrep,
    onGetSignature,
    initialOptions,
    eventHandlers,
  ])

  return {
    close,
    open,
    isDestroyed,
    isMinimized,
    isReady: scriptLoaded,
    isShowing,
  }
}

function createWidget(
  scriptLoaded: boolean,
  onClose: AnyFunction | undefined,
  onPreBatch: PreBatchHandler | undefined,
  onParamPrep: ParamPrepHandler | undefined,
  onGetSignature: GetSignatureHandler | undefined,
  initialOptions: UploadHookOptions,
  initialEventHandlers: UploadWidgetEventHandlers
) {
  logger.debug('UseUploadWidget - creating widget', {
    scriptLoaded: scriptLoaded,
    'window.cloudinaryWidget': window.cloudinaryWidget,
  })

  // wrap the callbacks that have unweildy returnback syntax with more modern promise syntax
  const preBatch: PreBatchCallback =
    onPreBatch &&
    ((cb, data) => {
      logger.debug('useUploadWidget-preBatch', { data })
      return cb(onPreBatch(data.files))
    })

  // wrap the callbacks that have unweildy returnback syntax with more modern promise syntax
  const prepareUploadParams: PrepareUploadParamsCallback =
    onParamPrep &&
    ((cb, params) => {
      logger.debug('useUploadWidget-prepareUploadParams', { params })
      onParamPrep(params).then((result) => {
        cb(result)
      })
    })

  // wrap the callbacks that have unweildy returnback syntax with more modern promise syntax
  const uploadSignature: UploadSignatureCallback =
    onGetSignature &&
    ((cb, params) => {
      logger.debug('useUploadWidget-uploadSignature', { params })
      onGetSignature(params).then((result) => {
        cb(result)
      })
    })

  // put these new callbacks into the options object
  const options = {
    ...initialOptions,
    prepareUploadParams,
    preBatch,
    uploadSignature,
  }

  const closeEventHandler: UploadWidgetEventHandler<CloseEvent> = (event) => {
    // regardless of the parent component or the hook, destroy the widget
    if (window.cloudinaryWidget) {
      logger.debug('UseUploadWidget - destroying widget', {
        'window.cloudinaryWidget': window.cloudinaryWidget,
      })
      window.cloudinaryWidget.destroy()
      window.cloudinaryWidget = undefined
    }

    // the parent component can handle the close event
    if (initialEventHandlers.onClose) {
      initialEventHandlers.onClose(event)
    }

    // the hook can also handle the close event
    if (onClose) {
      onClose()
    }
  }

  const eventHandlers = {
    ...initialEventHandlers,
    onClose: closeEventHandler,
  }

  // create an event handler that handles the single event and calls the correct one of many callbacks
  const eventHandler = createEventHandler(eventHandlers)

  // use the options and single event handler to create the widget
  const widget = window.cloudinary.createUploadWidget(
    options,
    eventHandler
  ) as WidgetInstance

  // save a reference to the widget in the window object
  window.cloudinaryWidget = widget

  return widget
}
