/* 
Zustand docs:
https://docs.pmnd.rs/zustand/getting-started/introduction
*/
import { create } from 'zustand'

import type {
  BrandDomainSelectModel,
  LanguageId,
  SessionId,
  UserDomainQueryModel,
  UserDomainSelectModel,
} from '@mntn-dev/domain-types'

export type State = {
  sessionId: SessionId | null
  curBrand: BrandDomainSelectModel | null
  darkMode: boolean
  lng: LanguageId
  me:
    | (UserDomainSelectModel & Pick<UserDomainQueryModel, 'brand' | 'agency'>)
    | null
}

type Actions = {
  setMe: (me: UserDomainSelectModel) => void
  setBrand: (brand: BrandDomainSelectModel) => void
  setLng: (lng: LanguageId) => void
  setDarkMode: (darkMode: boolean) => void
  reset: () => void
}

export type Store = State & Actions

export const initialState: State = {
  sessionId: null,
  curBrand: null,
  darkMode: false,
  lng: 'en',
  me: null,
}

export const useStore = create<Store>((set) => ({
  ...initialState,
  setMe: (me) => set({ me }),
  setDarkMode: (darkMode) => set({ darkMode }),
  setBrand: (curBrand) => set({ curBrand }),
  setLng: (lng) => set({ lng }),
  reset: () => set(initialState),
}))
