import type { GetImageDownloadUrlInput } from '@mntn-dev/files-shared'
import { toStringRecord } from '@mntn-dev/utilities'
import type { ClientFilesUtilitiesContext } from './context.ts'
import { logger } from './logger.ts'

export const getFileImageProxyUrl =
  ({ filesApiBaseUrl }: ClientFilesUtilitiesContext) =>
  ({ fileId, options }: GetImageDownloadUrlInput) => {
    try {
      const paramObject = {
        fileId,
        ...options,
      }
      const queryString = new URLSearchParams(
        toStringRecord(paramObject)
      ).toString()
      const result = `${filesApiBaseUrl}proxy?${queryString}`
      return result
    } catch (error) {
      logger.error('getFileImageProxyUrl', { error })
      throw error
    }
  }
