import { EnumBuilder } from '@mntn-dev/utility-types'
import { z } from 'zod'

export const [ImageFormats, ImageFormatSchema] = EnumBuilder(
  'gif',
  'png',
  'jpg',
  'bmp',
  'ico',
  'pdf',
  'tiff',
  'eps',
  'jpc',
  'jp2',
  'psd',
  'webp',
  'zip',
  'svg',
  'webm',
  'wdp',
  'hpx',
  'djvu',
  'ai',
  'flif',
  'bpg',
  'miff',
  'tga',
  'heic'
)
export type ImageFormat = z.infer<typeof ImageFormatSchema>

export const [VideoFormats, VideoFormatSchema] = EnumBuilder(
  'auto',
  'flv',
  'm3u8',
  'ts',
  'mov',
  'mkv',
  'mp4',
  'mpd',
  'ogv',
  'webm'
)
export type VideoFormat = z.infer<typeof VideoFormatSchema>

export const FileFormatSchema = z.union([ImageFormatSchema, VideoFormatSchema])

export type FileFormat = z.infer<typeof FileFormatSchema>
