import { z } from 'zod'

import { FileStorageKeySchema } from '@mntn-dev/domain-types'
import { HexColorSchema } from '@mntn-dev/utility-types'
import { StructuredMetadataSchema } from './structured-metadata.ts'

const AssetUpdatedRecordSchema = z.object({
  access_control_updated_at: z.string().optional(),
  context_updated_at: z.string().optional(),
  metadata_updated_at: z.string(),
  tags_updated_at: z.string().optional(),
  updated_at: z.string(),
})

const AssetDerivationSchema = z.object({
  transformation: z.string(),
  format: z.string(),
  bytes: z.number(),
  id: z.string(),
  url: z.string(),
  secure_url: z.string(),
})

const AssetColorSchema = z.tuple([HexColorSchema, z.number()])

const AssetVersionRecordSchema = z.object({
  version_id: z.string(),
  version: z.string(),
  format: z.string(),
  size: z.number(),
  time: z.string(),
  restorable: z.boolean(),
})

const AssetContextSchema = z.object({
  custom: z.record(z.string()),
})

const AssetFaceSchema = z.tuple([
  z.number(),
  z.number(),
  z.number(),
  z.number(),
])

export const AssetSchema = z.object({
  asset_id: z.string(),
  public_id: FileStorageKeySchema,
  version: z.number(),
  resource_type: z.string(),
  type: z.string(),
  created_at: z.string(),
  bytes: z.number(),
  width: z.number().optional(),
  height: z.number().optional(),
  asset_folder: z.string(),
  display_name: z.string(),
  url: z.string(),
  secure_url: z.string(),
  tags: z.array(z.string()).optional(),
  context: AssetContextSchema.optional(),
  metadata: StructuredMetadataSchema,
  last_updated: AssetUpdatedRecordSchema,
  next_cursor: z.string(),
  derived: z.array(AssetDerivationSchema),
  faces: z.array(AssetFaceSchema).optional(),
  illustration_score: z.number().optional(),
  semi_transparent: z.boolean().optional(),
  grayscale: z.boolean().optional(),
  colors: z.array(AssetColorSchema).optional(),
  versions: z.array(AssetVersionRecordSchema).optional(),
})

export type Asset = z.infer<typeof AssetSchema>
