import {
  FileCategories,
  type FileCategory,
  type FileContentType,
  FileContentTypeSchema,
  FileKindInfo,
} from '@mntn-dev/domain-types'
import { getFileExtension } from './get-file-extension.ts'
import type { SuccessEventInfo } from './upload-success-event.ts'

const isFileCategory = (category: string): category is FileCategory =>
  FileCategories.includes(category as FileCategory)

const getMimeTypeFromExtensions = (
  mimeTypeMap: Record<string, string[]>,
  extension: string
): FileContentType | null => {
  const normalizedExtension = extension.startsWith('.')
    ? extension
    : `.${extension}`

  const entry = Object.entries(mimeTypeMap).find(([_, extensions]) => {
    return extensions.includes(normalizedExtension)
  })

  if (!entry) {
    return null
  }

  const [mimeType] = entry

  return FileContentTypeSchema.parse(mimeType)
}

export const getFileCategory = (
  successEventInfo: Pick<
    SuccessEventInfo,
    'original_extension' | 'format' | 'public_id'
  >,
  categoryFilter?: FileCategory
): [FileCategory, FileContentType] => {
  const extension = getFileExtension(successEventInfo)

  for (const [fileKind, { map }] of Object.entries(FileKindInfo)) {
    const fileCategory = isFileCategory(fileKind) ? fileKind : 'raw'

    if (categoryFilter && categoryFilter !== fileCategory) {
      continue
    }

    const mimeType = getMimeTypeFromExtensions(map, extension)

    if (mimeType) {
      return [fileCategory, mimeType]
    }
  }

  throw new Error(
    `Failed to get category for file public_id ${successEventInfo.public_id}`
  )
}
