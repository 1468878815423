import { afterLastSlash } from '@mntn-dev/utilities'
import { getFileExtension } from './get-file-extension.ts'
import type { SuccessEventInfo } from './upload-success-event.ts'

export const getFileName = (
  successEventInfo: Pick<
    SuccessEventInfo,
    | 'original_filename'
    | 'original_extension'
    | 'format'
    | 'public_id'
    | 'display_name'
    | 'url'
  >
) => {
  const extension = getFileExtension(successEventInfo)

  const nameWithoutExtension =
    successEventInfo.original_filename ?? successEventInfo.display_name

  const name =
    nameWithoutExtension && extension
      ? `${nameWithoutExtension}.${extension}`
      : afterLastSlash(successEventInfo.url)

  return name
}
