import { z } from 'zod'
import { AudioCodecSchema } from './transformation-enums.ts'

export const AudioSchema = z.object({
  bit_rate: z.string(),
  channel_layout: z.string(),
  channels: z.number(),
  codec: AudioCodecSchema,
  frequency: z.number(),
})
