import { createEnv } from '@t3-oss/env-nextjs'
import { z } from 'zod'

export const env = (() => {
  /**
   * Override the AUTH0_BASE_URL env var with the CUSTOM_URL env var if it exists.
   * Otherwise, if the AUTH0_BASE_URL env var is not set, default to the VERCEL_URL env var.
   */

  const originalAuth0BaseUrl = process.env.AUTH0_BASE_URL

  const newAuth0BaseUrl =
    process.env.CUSTOM_URL ??
    process.env.AUTH0_BASE_URL ??
    (process.env.VERCEL_URL ? `https://${process.env.VERCEL_URL}` : undefined)

  if (originalAuth0BaseUrl !== newAuth0BaseUrl) {
    process.env.AUTH0_BASE_URL = newAuth0BaseUrl

    // biome-ignore lint/suspicious/noConsoleLog: can not user logger in env.js
    console.log('Modified environment:', {
      originalAuth0BaseUrl,
      newAuth0BaseUrl,
      'process.env.AUTH0_BASE_URL': process.env.AUTH0_BASE_URL,
      'process.env.CUSTOM_URL': process.env.CUSTOM_URL,
      'process.env.VERCEL_URL': process.env.VERCEL_URL,
    })
  }

  process.env.BASE_URL = process.env.BASE_URL ?? process.env.AUTH0_BASE_URL

  /**
   * @param {`d-${string}`} id - The ID of the email template.
   */
  const EmailTemplateSchema = (id) => z.string().min(1).catch(id)

  const result = createEnv({
    /**
     * Specify your server-side environment variables schema here. This way you can ensure the app isn't
     * built with invalid env vars.
     */
    server: {
      ABLY_API_KEY: z.string().min(1),
      AUTH0_AUDIENCE: z
        .string()
        .url()
        .catch('https://magicsky-dev.us.auth0.com/api/v2/'),
      AUTH0_BASE_URL: z.string().url(),
      AUTH0_CLIENT_ID: z.string().min(1),
      AUTH0_CLIENT_SECRET: z.string().min(1),
      AUTH0_ISSUER_BASE_URL: z.string().url(),
      AUTH0_SECRET: z.string().min(1),
      AWS_ACCESS_KEY_ID: z.string().min(1),
      AWS_BUCKET_NAME: z.enum([
        'quickframe-magicsky-dev',
        'quickframe-magicsky-prod',
      ]),
      AWS_REGION: z.enum(['us-east-1']),
      AWS_SECRET_ACCESS_KEY: z.string().min(1),
      BASE_URL: z.string().url(),
      CLOUDINARY_API_BASE_URL: z.string().url(),
      CLOUDINARY_API_KEY: z.string().min(1),
      CLOUDINARY_API_SECRET: z.string().min(1),
      CLOUDINARY_CLOUD_NAME: z.string().min(1),
      CUSTOM_URL: z.string().url().optional(),
      DATABASE_URL: z.string().url(),
      DISABLE_ALL_NOTIFICATIONS: z.enum(['true', 'false']).optional(),
      DISABLE_ASYNC_MESSAGES: z.enum(['true', 'false']).optional(),
      DISABLE_EMAIL_NOTIFICATIONS: z.enum(['true', 'false']).optional(),
      EMAIL_TEMPLATE_ALL_COMMENT_ADDED_TO_FEED: EmailTemplateSchema(
        'd-89a8abda683e4c02bfb844e480d0c751'
      ),
      EMAIL_TEMPLATE_BRAND_NEW_USER_INVITATION: EmailTemplateSchema(
        'd-3608b25ae17c4e5d985d1a1589c35836'
      ),
      EMAIL_TEMPLATE_BRAND_PROJECT_ASSIGNED: EmailTemplateSchema(
        'd-570097f0924b4f148b41a6690add7d2b'
      ),
      EMAIL_TEMPLATE_BRAND_PROJECT_COMPLETED: EmailTemplateSchema(
        'd-6eeeefa25fe1421a9fefe05da3b770dd'
      ),
      EMAIL_TEMPLATE_BRAND_PROJECT_FINAL_PRICE_SET: EmailTemplateSchema(
        'd-e7cd09e8ffd549bf959d25d9cbeaf49c'
      ),
      EMAIL_TEMPLATE_BRAND_PROJECT_MATCHED: EmailTemplateSchema(
        'd-570097f0924b4f148b41a6690add7d2b'
      ),
      EMAIL_TEMPLATE_INTERNAL_NEW_CUSTOM_PROJECT: EmailTemplateSchema(
        'd-e289a47f44e64970add0e581fcc7bb7e'
      ),
      EMAIL_TEMPLATE_INTERNAL_NEW_USER_INVITATION: EmailTemplateSchema(
        'd-dca20629e56447b5bc42be90cd139394'
      ),
      EMAIL_TEMPLATE_INTERNAL_ONBOARDING_BRAND_COMPLETED: EmailTemplateSchema(
        'd-1b791b2de42b4ee38b64fbdca42d59b9'
      ),
      EMAIL_TEMPLATE_INTERNAL_ONBOARDING_MAKER_COMPLETED: EmailTemplateSchema(
        'd-40a3c46f7fda4c5680f45c201d32befa'
      ),
      EMAIL_TEMPLATE_INTERNAL_PROJECT_COMPLETED: EmailTemplateSchema(
        'd-3771e4d0e60f41a5a921e86ef8cdc532'
      ),
      EMAIL_TEMPLATE_INTERNAL_PROJECT_MATCHED: EmailTemplateSchema(
        'd-f4fef87a2c96408eb93ea3f685233d8c'
      ),
      EMAIL_TEMPLATE_INTERNAL_PROJECT_NEEDS_OFFERS: EmailTemplateSchema(
        'd-67c7f377f2ef41cd975b5f46f7fe04e5'
      ),
      EMAIL_TEMPLATE_MAKER_OFFER_SENT: EmailTemplateSchema(
        'd-b715b7f752e047ae9ad00cffec1acf29'
      ),
      EMAIL_TEMPLATE_MAKER_NEW_USER_INVITATION: EmailTemplateSchema(
        'd-d3139cb0744d4dd4a975ffa67d55ce81'
      ),
      EMAIL_TEMPLATE_MAKER_OFFER_CANCELLED: EmailTemplateSchema(
        'd-dc1c6f656d454220bd2ef9bed6af5c28'
      ),
      EMAIL_TEMPLATE_MAKER_PROJECT_ASSIGNED: EmailTemplateSchema(
        'd-8e335b9198bc42ecba2477fdb899458b'
      ),
      EMAIL_TEMPLATE_MAKER_PROJECT_MATCHED: EmailTemplateSchema(
        'd-8e335b9198bc42ecba2477fdb899458b'
      ),
      EMAIL_TEMPLATE_MAKER_PROJECT_OFFER_EXPIRED: EmailTemplateSchema(
        'd-ed9dfa890d52430391ffeb37bd389d0a'
      ),
      EMAIL_TEMPLATE_POST_PRODUCTION_DELIVERABLES_READY_TO_REVIEW:
        EmailTemplateSchema('d-e88b91b77c524af1a374ce01e6748487'),
      EMAIL_TEMPLATE_POST_PRODUCTION_DELIVERABLES_REVIEWED: EmailTemplateSchema(
        'd-a2f759ade1124983a2e95d62882cb7e5'
      ),
      EMAIL_TEMPLATE_PRE_PRODUCTION_BRAND_FEEDBACK_SUBMITTED:
        EmailTemplateSchema('d-9c9f654ad2ee4a0ba68fe8c6a84dd64c'),
      EMAIL_TEMPLATE_PRE_PRODUCTION_COMPLETE: EmailTemplateSchema(
        'd-10d6a98659d74c50a846944a031fdb0d'
      ),
      EMAIL_TEMPLATE_PRE_PRODUCTION_MAKER_PROPOSAL_SUBMITTED:
        EmailTemplateSchema('d-b476c52152844d259a8ea35a738a0598'),
      EMAIL_TEMPLATE_PROJECT_IN_PRODUCTION: EmailTemplateSchema(
        'd-f1c8d5f0f4a3483887948d4366bd8134'
      ),
      EXIF_QF_CONFIG_PATH: z
        .string()
        .min(1)
        .catch('/opt/tagger/qf-xmp-custom-namespace.pl'),
      EXIF_TOOL_PATH: z.string().min(1).catch('/opt/bin/exiftool'),
      FILE_URL_WINDOW_SECONDS: z.coerce.number().positive().catch(60),
      HIGHLIGHT_PROJECT_ID: z.string().min(1).optional(),
      LAUNCHDARKLY_SDK_KEY: z.string().min(1),
      META_TAGGER_URL: z.string().url(),
      MNTN_COST_MARGIN_PERCENT: z.coerce
        .number()
        .int()
        .nonnegative()
        .max(99)
        .catch(50),
      NGROK_URL: z.string().url().optional(),
      NODE_ENV: z.enum(['development', 'production', 'test']),
      SENDGRID_API_KEY: z.string().min(1),
      SENDGRID_VERIFIED_SENDER_EMAIL: z.string().email(),
      SENDGRID_VERIFIED_SENDER_NAME: z.string(),
      SENTRY_AUTH_TOKEN: z.string().optional(),
      SENTRY_DSN: z.string().url().optional(),
      SENTRY_ORG: z.string().optional(),
      SENTRY_PROJECT: z.string().optional(),
      VERCEL: z.string().optional(),
      VERCEL_ENV: z.string().optional(),
      VERCEL_URL: z.string().optional(),
    },
    /**
     * Specify your client-side environment variables schema here.
     * For them to be exposed to the client, prefix them with `NEXT_PUBLIC_`.
     */
    client: {
      NEXT_PUBLIC_INTERCOM_APP_ID: z.string().min(1),
      NEXT_PUBLIC_CLOUDINARY_API_KEY: z.string().min(1),
      NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME: z.string().min(1),
      NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_ID: z.string().min(1),
      NEXT_PUBLIC_LOG_LEVEL: z
        .enum(['error', 'warn', 'info', 'debug'])
        .catch('error'),
    },
    /**
     * Destructure all variables from `process.env` to make sure they aren't tree-shaken away.
     */
    runtimeEnv: {
      ABLY_API_KEY: process.env.ABLY_API_KEY,
      AUTH0_AUDIENCE: process.env.AUTH0_AUDIENCE,
      AUTH0_BASE_URL: process.env.AUTH0_BASE_URL,
      AUTH0_CLIENT_ID: process.env.AUTH0_CLIENT_ID,
      AUTH0_CLIENT_SECRET: process.env.AUTH0_CLIENT_SECRET,
      AUTH0_ISSUER_BASE_URL: process.env.AUTH0_ISSUER_BASE_URL,
      AUTH0_SECRET: process.env.AUTH0_SECRET,
      BASE_URL: process.env.BASE_URL,
      AWS_ACCESS_KEY_ID: process.env.AWS_ACCESS_KEY_ID,
      AWS_BUCKET_NAME: process.env.AWS_BUCKET_NAME,
      AWS_REGION: process.env.AWS_REGION,
      AWS_SECRET_ACCESS_KEY: process.env.AWS_SECRET_ACCESS_KEY,
      CUSTOM_URL: process.env.CUSTOM_URL,
      CLOUDINARY_API_BASE_URL: process.env.CLOUDINARY_API_BASE_URL,
      CLOUDINARY_API_KEY: process.env.CLOUDINARY_API_KEY,
      CLOUDINARY_API_SECRET: process.env.CLOUDINARY_API_SECRET,
      CLOUDINARY_CLOUD_NAME: process.env.CLOUDINARY_CLOUD_NAME,
      DATABASE_URL: process.env.DATABASE_URL,
      DISABLE_ALL_NOTIFICATIONS: process.env.DISABLE_ALL_NOTIFICATIONS,
      DISABLE_ASYNC_MESSAGES: process.env.DISABLE_ASYNC_MESSAGES,
      DISABLE_EMAIL_NOTIFICATIONS: process.env.DISABLE_ALL_NOTIFICATIONS,
      FILE_URL_WINDOW_SECONDS: process.env.FILE_URL_WINDOW_SECONDS,
      EMAIL_TEMPLATE_ALL_COMMENT_ADDED_TO_FEED:
        process.env.EMAIL_TEMPLATE_ALL_COMMENT_ADDED_TO_FEED,
      EMAIL_TEMPLATE_BRAND_NEW_USER_INVITATION:
        process.env.EMAIL_TEMPLATE_BRAND_NEW_USER_INVITATION,
      EMAIL_TEMPLATE_BRAND_PROJECT_ASSIGNED:
        process.env.EMAIL_TEMPLATE_BRAND_PROJECT_ASSIGNED,
      EMAIL_TEMPLATE_BRAND_PROJECT_COMPLETED:
        process.env.EMAIL_TEMPLATE_BRAND_PROJECT_COMPLETED,
      EMAIL_TEMPLATE_BRAND_PROJECT_FINAL_PRICE_SET:
        process.env.EMAIL_TEMPLATE_BRAND_PROJECT_FINAL_PRICE_SET,
      EMAIL_TEMPLATE_BRAND_PROJECT_MATCHED:
        process.env.EMAIL_TEMPLATE_BRAND_PROJECT_MATCHED,
      EMAIL_TEMPLATE_INTERNAL_NEW_CUSTOM_PROJECT:
        process.env.EMAIL_TEMPLATE_INTERNAL_NEW_CUSTOM_PROJECT,
      EMAIL_TEMPLATE_INTERNAL_NEW_USER_INVITATION:
        process.env.EMAIL_TEMPLATE_INTERNAL_NEW_USER_INVITATION,
      EMAIL_TEMPLATE_INTERNAL_ONBOARDING_BRAND_COMPLETED:
        process.env.EMAIL_TEMPLATE_INTERNAL_ONBOARDING_BRAND_COMPLETED,
      EMAIL_TEMPLATE_INTERNAL_ONBOARDING_MAKER_COMPLETED:
        process.env.EMAIL_TEMPLATE_INTERNAL_ONBOARDING_MAKER_COMPLETED,
      EMAIL_TEMPLATE_INTERNAL_PROJECT_COMPLETED:
        process.env.EMAIL_TEMPLATE_INTERNAL_PROJECT_COMPLETED,
      EMAIL_TEMPLATE_INTERNAL_PROJECT_MATCHED:
        process.env.EMAIL_TEMPLATE_INTERNAL_PROJECT_MATCHED,
      EMAIL_TEMPLATE_INTERNAL_PROJECT_NEEDS_OFFERS:
        process.env.EMAIL_TEMPLATE_INTERNAL_PROJECT_NEEDS_OFFERS,
      EMAIL_TEMPLATE_MAKER_OFFER_SENT:
        process.env.EMAIL_TEMPLATE_MAKER_OFFER_SENT,
      EMAIL_TEMPLATE_MAKER_NEW_USER_INVITATION:
        process.env.EMAIL_TEMPLATE_MAKER_NEW_USER_INVITATION,
      EMAIL_TEMPLATE_MAKER_OFFER_CANCELLED:
        process.env.EMAIL_TEMPLATE_MAKER_OFFER_CANCELLED,
      EMAIL_TEMPLATE_MAKER_PROJECT_ASSIGNED:
        process.env.EMAIL_TEMPLATE_MAKER_JOB_ASSIGNED,
      EMAIL_TEMPLATE_MAKER_PROJECT_MATCHED:
        process.env.EMAIL_TEMPLATE_MAKER_PROJECT_MATCHED,
      EMAIL_TEMPLATE_MAKER_PROJECT_OFFER_EXPIRED:
        process.env.EMAIL_TEMPLATE_MAKER_PROJECT_OFFER_EXPIRED,
      EMAIL_TEMPLATE_POST_PRODUCTION_DELIVERABLES_READY_TO_REVIEW:
        process.env.EMAIL_TEMPLATE_POST_PRODUCTION_DELIVERABLES_READY_TO_REVIEW,
      EMAIL_TEMPLATE_POST_PRODUCTION_DELIVERABLES_REVIEWED:
        process.env.EMAIL_TEMPLATE_POST_PRODUCTION_DELIVERABLES_REVIEWED,
      EMAIL_TEMPLATE_PRE_PRODUCTION_BRAND_FEEDBACK_SUBMITTED:
        process.env.EMAIL_TEMPLATE_PRE_PRODUCTION_BRAND_FEEDBACK_SUBMITTED,
      EMAIL_TEMPLATE_PRE_PRODUCTION_COMPLETE:
        process.env.EMAIL_TEMPLATE_PRE_PRODUCTION_COMPLETE,
      EMAIL_TEMPLATE_PRE_PRODUCTION_MAKER_PROPOSAL_SUBMITTED:
        process.env.EMAIL_TEMPLATE_PRE_PRODUCTION_MAKER_PROPOSAL_SUBMITTED,
      EMAIL_TEMPLATE_PROJECT_IN_PRODUCTION:
        process.env.EMAIL_TEMPLATE_PROJECT_IN_PRODUCTION,
      EXIF_QF_CONFIG_PATH: process.env.EXIF_QF_CONFIG_PATH,
      EXIF_TOOL_PATH: process.env.EXIF_TOOL_PATH,
      HIGHLIGHT_PROJECT_ID: process.env.HIGHLIGHT_PROJECT_ID,
      LAUNCHDARKLY_SDK_KEY: process.env.LAUNCHDARKLY_SDK_KEY,
      META_TAGGER_URL: process.env.META_TAGGER_URL,
      MNTN_COST_MARGIN_PERCENT: process.env.MNTN_COST_MARGIN_PERCENT,
      NEXT_PUBLIC_INTERCOM_APP_ID: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
      NEXT_PUBLIC_CLOUDINARY_API_KEY:
        process.env.NEXT_PUBLIC_CLOUDINARY_API_KEY,
      NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME:
        process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME,
      NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_ID:
        process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_ID,
      NEXT_PUBLIC_LOG_LEVEL: process.env.NEXT_PUBLIC_LOG_LEVEL,
      NGROK_URL: process.env.NGROK_URL,
      NODE_ENV: process.env.NODE_ENV,
      SENDGRID_API_KEY: process.env.SENDGRID_API_KEY,
      SENDGRID_VERIFIED_SENDER_EMAIL:
        process.env.SENDGRID_VERIFIED_SENDER_EMAIL,
      SENDGRID_VERIFIED_SENDER_NAME: process.env.SENDGRID_VERIFIED_SENDER_NAME,
      SENTRY_AUTH_TOKEN: process.env.SENTRY_AUTH_TOKEN,
      SENTRY_DSN: process.env.SENTRY_DSN,
      SENTRY_ORG: process.env.SENTRY_ORG,
      SENTRY_PROJECT: process.env.SENTRY_PROJECT,
      VERCEL: process.env.VERCEL,
      VERCEL_ENV: process.env.VERCEL_ENV,
      VERCEL_URL: process.env.VERCEL_URL,
    },
    skipValidation:
      process.env.NODE_ENV === 'test' ||
      !!process.env.CI ||
      !!process.env.SKIP_ENV_VALIDATION,
  })

  return result
})()
