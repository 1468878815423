import { EnumBuilder } from '@mntn-dev/utility-types'
import type { z } from 'zod'

export const [UploadSources, UploadSourceSchema] = EnumBuilder(
  'local',
  'url',
  'camera',
  'dropbox',
  'image_search',
  'shutterstock',
  'gettyimages',
  'istock',
  'unsplash',
  'google_drive'
)

export type UploadSource = z.infer<typeof UploadSourceSchema>
