import {
  type FileArea,
  type FileCategory,
  FileId,
  FileStorageKey,
  type FolderUrn,
} from '@mntn-dev/domain-types'
import {
  type RecordUploadInput,
  type SuccessEventInfo,
  isPublicUploadRequest,
  getFileCategory,
  getFileName,
} from '@mntn-dev/files-shared'
import { logger } from './logger.ts'
import type { TrpcReactUtilsClient } from './trpc-types.ts'

const mutate = async (
  input: RecordUploadInput,
  trpcClient: TrpcReactUtilsClient
) => {
  if (isPublicUploadRequest(input)) {
    return await trpcClient.files.public.recordUpload.mutate(input)
  }

  return await trpcClient.files.recordUpload.mutate(input)
}

export const recordUpload = async (
  fileArea: FileArea,
  folderUrn: FolderUrn,
  successEventInfo: SuccessEventInfo,
  trpcClient: TrpcReactUtilsClient,
  categoryOverride?: FileCategory
) => {
  const fileId = FileId()

  logger.debug('recordUpload', {
    fileId,
    fileArea,
    folderUrn,
    successEventInfo,
  })

  const name = getFileName(successEventInfo)
  const [category, mimeType] = getFileCategory(
    successEventInfo,
    categoryOverride
  )

  const input: RecordUploadInput = {
    category,
    area: fileArea,
    folderUrn,
    name,
    size: successEventInfo.bytes,
    mimeType,
    fileId,
    fileResourceType: successEventInfo.resource_type,
    storageKey: FileStorageKey(successEventInfo.public_id),
  }

  const { asset, file } = await mutate(input, trpcClient)

  return { asset, file }
}
