import {
  BrandIdSchema,
  DeliverableIdSchema,
  FileIdSchema,
  ProjectIdSchema,
} from '@mntn-dev/domain-types'
import { z } from 'zod'

export const QuickframeTagsSchema = z.object({
  assetFileUuid: FileIdSchema,
  brandUuid: BrandIdSchema,
  projectUuid: ProjectIdSchema,
  deliverableUuid: DeliverableIdSchema,
})

export type QuickframeTags = z.infer<typeof QuickframeTagsSchema>
