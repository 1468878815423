import { EnumBuilder } from '@mntn-dev/utility-types'
import type { z } from 'zod'

export const [AccessModes, AccessModeSchema] = EnumBuilder(
  'public',
  'authenticated'
)
export type AccessMode = z.infer<typeof AccessModeSchema>

export const [ModerationKinds, ModerationKindSchema] = EnumBuilder(
  'manual',
  'webpurify',
  'aws_rek',
  'metascan'
)
export type ModerationKind = z.infer<typeof ModerationKindSchema>

export const [DeliveryTypes, DeliveryTypeSchema] = EnumBuilder(
  'upload',
  'private',
  'authenticated',
  'fetch',
  'multi',
  'text',
  'asset',
  'list',
  'facebook',
  'twitter',
  'twitter_name',
  'instagram',
  'gravatar',
  'youtube',
  'hulu',
  'vimeo',
  'animoto',
  'worldstarhiphop',
  'dailymotion'
)
export type DeliveryType = z.infer<typeof DeliveryTypeSchema>
