import {
  type ParamPrepHandler,
  type SignApiParametersInput,
  isPublicSigningRequest,
} from '@mntn-dev/files-shared'
import { asArray, assertNonEmptyArray } from '@mntn-dev/utilities'

import type { FileArea } from '@mntn-dev/domain-types'
import { logger } from './logger.ts'
import type { TrpcReactUtilsClient } from './trpc-types.ts'

const signParameters = async (
  input: SignApiParametersInput,
  trpcClient: TrpcReactUtilsClient
) => {
  if (isPublicSigningRequest(input)) {
    return await trpcClient.files.public.signApiParameters.query(input)
  }

  return await trpcClient.files.signApiParameters.query(input)
}

export const prepParams = (
  fileArea: FileArea,
  trpcClient: TrpcReactUtilsClient,
  cloudinaryKey: string
): ParamPrepHandler => {
  return async (params) => {
    const paramBundles = asArray(params)

    const signatureResults = await Promise.all(
      paramBundles.map(async (bundle) => {
        bundle.use_filename = false
        bundle.unique_filename = true
        const signatureInfo = await signParameters(
          {
            params: bundle,
            fileArea,
          },
          trpcClient
        )

        return {
          ...signatureInfo.params,
          apiKey: cloudinaryKey,
          signature: signatureInfo.signature,
          uploadSignatureTimestamp: signatureInfo.timestamp,
        }
      })
    )

    assertNonEmptyArray(signatureResults)

    const result =
      signatureResults.length === 1 ? signatureResults[0] : signatureResults

    logger.debug('useUploadWidget-paramPrepHandler', { params, result })

    return result
  }
}
