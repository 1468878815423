import type { FileArea, FileCategory, FolderUrn } from '@mntn-dev/domain-types'
import {
  type SuccessEvent,
  SuccessEventInfoSchema,
  type UploadWidgetEventHandler,
} from '@mntn-dev/files-shared'
import type { AfterUploadHandler } from './after-upload-event.ts'
import { logger } from './logger.ts'
import { recordUpload } from './record-upload.ts'
import type { TrpcReactUtilsClient } from './trpc-types.ts'

export const createSuccessHandler = (
  fileArea: FileArea,
  folderUrn: FolderUrn,
  trpcClient: TrpcReactUtilsClient,
  category?: FileCategory,
  onAfterUpload?: AfterUploadHandler
) => {
  const successHandler: UploadWidgetEventHandler<SuccessEvent> = async (
    uploadSuccessEventInfo
  ) => {
    const successEventInfo = SuccessEventInfoSchema.parse(
      uploadSuccessEventInfo
    )

    logger.debug(
      `uploadWidget successHandler - asset: ${successEventInfo.public_id}`,
      { successEventInfo }
    )

    const uploadResult = await recordUpload(
      fileArea,
      folderUrn,
      successEventInfo,
      trpcClient,
      category
    )

    logger.debug(
      `uploadWidget successHandler - file: ${uploadResult.file.fileId}`,
      { uploadResult }
    )

    if (onAfterUpload) {
      onAfterUpload({
        ...uploadResult,
        successEventInfo,
      })
    }
  }

  return successHandler
}
