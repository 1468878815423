import { type FileArea, PublicFileAreaSchema } from '@mntn-dev/domain-types'
import type {
  RecordPublicUploadInput,
  RecordUploadInput,
  SignApiParametersInput,
  SignApiParametersPublicInput,
} from './inputs.ts'

export const isPublicFileArea = (fileArea: FileArea) =>
  PublicFileAreaSchema.safeParse(fileArea).success

export const isPublicUploadRequest = (
  input: RecordUploadInput
): input is RecordPublicUploadInput => isPublicFileArea(input.area)

export const isPublicSigningRequest = (
  input: SignApiParametersInput
): input is SignApiParametersPublicInput => isPublicFileArea(input.fileArea)
