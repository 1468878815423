import type { FileId } from '@mntn-dev/domain-types'
import type { ImageUrlOptions } from '@mntn-dev/files-shared'
import { toStringRecord } from '@mntn-dev/utilities'
import type { ClientFilesUtilitiesContext } from './context.ts'
import { logger } from './logger.ts'

type getFileDownloadProxyUrlArgs = {
  fileId: FileId
  options?: ImageUrlOptions
}

export const getFileDownloadProxyUrl =
  ({ filesApiBaseUrl }: ClientFilesUtilitiesContext) =>
  ({ fileId, options = {} }: getFileDownloadProxyUrlArgs) => {
    try {
      const paramObject = {
        fileId,
        ...options,
        flags: 'attachment', // `attachment` flag is used to return a asset download URL
      }
      const queryString = new URLSearchParams(
        toStringRecord(paramObject)
      ).toString()
      const result = `${filesApiBaseUrl}proxy?${queryString}`
      return result
    } catch (error) {
      logger.error('getFileDownloadProxyUrl', { error })
      throw error
    }
  }
