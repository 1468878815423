import { z } from 'zod'

export const VideoSchema = z.object({
  bit_rate: z.string(),
  codec: z.string(),
  dar: z.string(),
  level: z.number(),
  pix_format: z.string(),
  profile: z.string(),
  time_base: z.string(),
})

export type Video = z.infer<typeof VideoSchema>
