import { type FileKind, FileKindInfo, type Url } from '@mntn-dev/domain-types'
import { logger } from './logger.ts'

type Args = {
  downloadUrl: Url
  suggestedName: string
  fileKinds?: FileKind[]
}
/**
 * Save a file to the client's file system.
 *
 * This function uses the File System Access API to show a "Save As" dialog.
 * If the browser doesn't support the File System Access API, it falls back to a manual download.
 *
 * WARNING: This function will log a warning if it accesses File System Access API
 * while a debugger is attached to the browser. It will then fall back to a manual download.
 *
 * @param param0
 */
export const saveFileToClient = async ({
  downloadUrl,
  suggestedName,
  fileKinds,
}: Args) => {
  const types = fileKinds?.map((kind) => ({
    description: FileKindInfo[kind].description,
    accept: FileKindInfo[kind].map,
  }))

  if (window.showSaveFilePicker) {
    try {
      /**
       * WARNING: The next line will fail if the browser is attached to a debugger.
       */
      const fileHandle = await window.showSaveFilePicker({
        suggestedName,
        types,
      })

      // Create a writable stream to save the file
      const writableStream = await fileHandle.createWritable()

      // Fetch the file from the URL
      const response = await fetch(downloadUrl)
      const blob = await response.blob()

      // Write the fetched data to the writable stream
      await writableStream.write(blob)

      // Close the writable stream
      await writableStream.close()
    } catch (error) {
      if (error instanceof DOMException && error.name === 'SecurityError') {
        logger.warn('security error in saveFileToClient', { error })
        downloadFallback(downloadUrl, suggestedName)
      } else {
        logger.error('error in saveFileToClient', { error })
      }
    }
  } else {
    // Fallback for browsers that don't support File System Access API
    downloadFallback(downloadUrl, suggestedName)
  }
}

function downloadFallback(downloadUrl: string, suggestedName: string) {
  const anchor = document.createElement('a')
  anchor.href = downloadUrl
  anchor.download = suggestedName

  // Append the anchor to the body (necessary for Firefox)
  document.body.appendChild(anchor)

  // Programmatically click the anchor to trigger the download and open "Save As" dialog
  anchor.click()

  // Remove the anchor from the document (cleanup)
  if (document.body.contains(anchor)) {
    document.body.removeChild(anchor)
  }
}
