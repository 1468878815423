import { useCallback } from 'react'

import type {
  DestroyWidgetOptions,
  OpenWidgetOptions,
  UpdateWidgetOptions,
  WidgetInstance,
} from '../types/widget-instance.ts'

export const useImperativeFunctions = (
  widgetInstance: WidgetInstance | undefined
) => {
  const open = useCallback(
    (source?: string, options?: OpenWidgetOptions) => {
      if (widgetInstance) {
        widgetInstance.open(source, options)
      }
    },
    [widgetInstance]
  )

  const close = useCallback(() => {
    if (widgetInstance) {
      widgetInstance.close()
    }
  }, [widgetInstance])

  const update = useCallback(
    (options?: UpdateWidgetOptions) => {
      if (widgetInstance) {
        widgetInstance.update(options)
      }
    },
    [widgetInstance]
  )

  const hide = useCallback(() => {
    if (widgetInstance) {
      widgetInstance.hide()
    }
  }, [widgetInstance])

  const show = useCallback(() => {
    if (widgetInstance) {
      widgetInstance.show()
    }
  }, [widgetInstance])

  const minimize = useCallback(() => {
    if (widgetInstance) {
      widgetInstance.minimize()
    }
  }, [widgetInstance])

  const destroy = useCallback(
    (options?: DestroyWidgetOptions) => {
      if (widgetInstance) {
        widgetInstance.destroy(options)
      }
    },
    [widgetInstance]
  )

  return {
    open,
    close,
    update,
    hide,
    show,
    minimize,
    destroy,
  }
}
