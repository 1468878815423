import type {
  UploadWidgetEvent,
  UploadWidgetEventHandlers,
} from '@mntn-dev/files-shared'
import { UnhandledUnionError } from '@mntn-dev/utilities'
import { logger } from '../logger.ts'

export const createEventHandler =
  (eventHandlers: UploadWidgetEventHandlers) =>
  async (error: string | null, result: UploadWidgetEvent) => {
    if (error) {
      eventHandlers.onError?.(error)
      logger.error('Upload error:', { error })
      return
    }
    logger.debug(`Upload Widget Event - ${result.event}`, { ...result })
    switch (result.event) {
      case 'abort':
        await eventHandlers.onAbort?.(result.info)
        break
      case 'batch-cancelled':
        await eventHandlers.onBatchCancelled?.(result.info)
        break
      case 'close':
        await eventHandlers.onClose?.(result.info)
        break
      case 'display-changed':
        await eventHandlers.onDisplayChanged?.(result.info)
        break
      case 'error':
        await eventHandlers.onError?.(result.info)
        break
      case 'publicid':
        await eventHandlers.onPublicId?.(result.info)
        break
      case 'queues-end':
        await eventHandlers.onQueuesEnd?.(result.info)
        break
      case 'queues-start':
        await eventHandlers.onQueuesStart?.(result.info)
        break
      case 'retry':
        await eventHandlers.onRetry?.(result.info)
        break
      case 'show-completed':
        await eventHandlers.onShowCompleted?.(result.info)
        break
      case 'source-changed':
        await eventHandlers.onSourceChanged?.(result.info)
        break
      case 'success':
        await eventHandlers.onSuccess?.(result.info)
        break
      case 'tags':
        await eventHandlers.onTags?.(result.info)
        break
      case 'upload-added':
        await eventHandlers.onUploadAdded?.(result.info)
        break
      default:
        throw new UnhandledUnionError(result)
    }
  }
