import { EnumBuilder, OrAnyStringSchema } from '@mntn-dev/utility-types'
import { z } from 'zod'

export const [HorizontalSides, HorizontalSidesSchema] = EnumBuilder(
  'left',
  'right'
)
export type HorizontalSide = z.infer<typeof HorizontalSidesSchema>

export const [AutomaticInteractionAreas, AutomaticInteractionAreaSchema] =
  EnumBuilder('portrait', 'landscape', 'all', 'center')
export type AutomaticInteractionArea = z.infer<
  typeof AutomaticInteractionAreaSchema
>

export const [SuggestedGoogleFonts, SuggestedGoogleFontSchema] = EnumBuilder(
  'Roboto',
  'Open Sans',
  'Lato',
  'Montserrat',
  'Source Sans Pro',
  'Oswald',
  'Raleway',
  'Merriweather',
  'Nunito',
  'Ubuntu',
  'Playfair Display',
  'Noto Sans',
  'Roboto Condensed',
  'PT Sans',
  'Lora',
  'Dancing Script',
  'Rubik',
  'Titillium Web',
  'Inconsolata',
  'Bebas Neue',
  'Libre Baskerville',
  'Josefin Sans',
  'Fira Sans',
  'Poppins',
  'Arimo'
)
export type SuggestedGoogleFont = z.infer<typeof SuggestedGoogleFontSchema>

export const FontFaceSchema = z.union([
  SuggestedGoogleFontSchema,
  z.literal('inherit'),
  z.literal(false),
  OrAnyStringSchema,
])

export type FontFace = z.infer<typeof FontFaceSchema>

export const [VideoPlaylistStyles, VideoPlaylistStyleSchema] = EnumBuilder(
  'minimal',
  'default'
)
export type VideoPlaylistStyle = z.infer<typeof VideoPlaylistStyleSchema>

export const [VideoPlaylistOrientations, VideoPlaylistOrientationSchema] =
  EnumBuilder('vertical', 'horizontal')
export type VideoPlaylistOrientation = z.infer<
  typeof VideoPlaylistOrientationSchema
>

export const [VideoPlaylistVisableTotals, VideoPlaylistVisableTotalSchema] =
  EnumBuilder('1', '2', '3', '4')

export const VideoPlaylistTotalSchema = z.union([
  VideoPlaylistVisableTotalSchema,
  OrAnyStringSchema,
])

export type VideoPlaylistTotal = z.infer<typeof VideoPlaylistTotalSchema>

export const [VideoPlayerAutoplayModes, VideoPlayerAutoplayModeSchema] =
  EnumBuilder('never', 'always', 'on-scroll')
export type VideoPlayerAutoplayMode = z.infer<
  typeof VideoPlayerAutoplayModeSchema
>

export const [VideoPlayerPreloadOptions, VideoPlayerPreloadOptionSchema] =
  EnumBuilder('auto', 'metadata', 'none')
export type VideoPlayerPreloadOption = z.infer<
  typeof VideoPlayerPreloadOptionSchema
>

export const [SuggestedVideoSourceTypes, SuggestedVideoSourceTypeSchema] =
  EnumBuilder(
    'audio',
    'auto',
    'dash',
    'f_auto',
    'flv',
    'hls',
    'm3u8',
    'mp4',
    'mp4/h264',
    'mp4/h264/aac',
    'mp4/h265',
    'ogg',
    'progressive',
    'ts',
    'webm',
    'webm/vp8',
    'webm/vp9'
  )

export const VideoSourceTypeSchema = z.union([
  SuggestedVideoSourceTypeSchema,
  OrAnyStringSchema,
])

export type VideoSourceType = z.infer<typeof VideoSourceTypeSchema>

export const [TextTrackThemes, TextTrackThemeSchema] = EnumBuilder(
  'default',
  'videojs-default',
  'yellow-outlined',
  'player-colors',
  '3d'
)

export type TextTrackTheme = z.infer<typeof TextTrackThemeSchema>
